<template>
  <component :is="commonComponent" id="disclamimer" title="Disclamimer">
    <section id="disclamimer-content" class="pc_padding">
      <div class="common-content-head">
        Disclaimer
      </div>

      <div class="common-content-row">
        The information provided by ReelSpot.click is for general informational purposes only.
        All information on the site is provided in good faith; however, we make no representation or
        warranty of any kind, express or implied, regarding the accuracy, adequacy, validity,
        reliability, availability, or completeness of any information on the site.
      </div>

      <div class="common-content-head">
        Copyright Disclaimer
      </div>

      <div class="common-content-row">
        ReelSpot.click uses data from third-party data providers, including movie-related
        images and data. We respect the intellectual property rights of others and strive to ensure
        that we have appropriate rights to use such content. If you believe that any content on our
        site infringes upon your copyright, please contact us immediately at
        reelspot.support@gmail.com so that we can address your concerns promptly.
      </div>

      <div class="common-content-head">
        No Professional Advice
      </div>

      <div class="common-content-row">
        ReelSpot.click is not a professional service provider. The content on this website is
        provided for entertainment and informational purposes only and should not be construed as
        professional advice. Always seek the advice of a qualified professional with any questions
        you may have regarding a particular matter.
      </div>

      <div class="common-content-head">
        External Links Disclaimer
      </div>

      <div class="common-content-row">
        ReelSpot.click may contain (or you may be sent through the site) links to other
        websites or content belonging to or originating from third parties or links to websites and
        features in banners or other advertising. Such external links are not investigated,
        monitored, or checked for accuracy, adequacy, validity, reliability, availability, or
        completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the
        accuracy or reliability of any information offered by third-party websites linked through
        the site or any website or feature linked in any banner or other advertising. We will not be
        a party to or in any way be responsible for monitoring any transaction between you and
        third-party providers of products or services.
      </div>

      <div class="common-content-head">
        Errors and Omissions Disclaimer
      </div>

      <div class="common-content-row">
        While we strive to ensure that the information on this site is accurate and up to date, we
        are not responsible for any errors or omissions, or for the results obtained from the use of
        this information. All information on the site is provided &quot;as is,&quot; with no
        guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of
        this information, and without warranty of any kind, express or implied.
      </div>

      <div class="common-content-head">
        Personal Responsibility
      </div>

      <div class="common-content-row">
        You acknowledge that you are using our site voluntarily and that any choices, actions, and
        results now and in the future are solely your responsibility. ReelSpot.click will not
        be liable to you or any other party for any decision made or action taken in reliance on the
        information given by the site.
      </div>

      <div class="common-content-head">
        Limitation of Liability
      </div>

      <div class="common-content-row">
        In no event shall ReelSpot.click, nor its directors, employees, partners, agents,
        suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or
        punitive damages, including without limitation, loss of profits, data, use, goodwill, or
        other intangible losses, resulting from (i) your use or inability to use the site; (ii) any
        unauthorized access to or use of our servers and/or any personal information stored therein;
        (iii) any interruption or cessation of transmission to or from the site; (iv) any bugs,
        viruses, trojan horses, or the like that may be transmitted to or through the site by any
        third party; (v) any errors or omissions in any content or for any loss or damage incurred
        as a result of the use of any content posted, emailed, transmitted, or otherwise made
        available through the site; and/or (vi) the defamatory, offensive, or illegal conduct of any
        third party. In no event shall ReelSpot.click be liable for any claims, damages, or
        losses arising out of or in connection with your use of the site.
      </div>

      <div class="common-content-head">
        Changes to This Disclaimer
      </div>

      <div class="common-content-row">
        We reserve the right to modify this disclaimer at any time. By continuing to use the site
        after any changes are posted, you acknowledge and agree to the updated disclaimer.
      </div>

      <div class="common-content-head">
        Contact Us
      </div>

      <div class="common-content-row">
        If you have any questions about this disclaimer, please contact us at
        reelspot.support@gmail.com.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  name: 'Disclaimer',
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Disclaimer | Daily Classic Movie Recommendations - ReelSpot.click',
      meta: [
        {
          name: 'description',
          content: 'Discover a classic movie every day at ReelSpot.click. We provide detailed synopses, reviews, curated lists, and exclusive interviews to help you relive the golden age of cinema.'
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>